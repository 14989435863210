// import { mapState } from "vuex";
import EventBus from "@/eventBus/eventBus.js";
import { querySOSRecord } from "@/administration/history.js";
import beasconfig from "@/api/beasconfig.js";
import axios from "axios";
import qs from "qs";
import global from "@/method/globalMethod.js";

const markersos = {
    data() {
        return {
            sosList: [],
            markerSos: [],       //sos定位点
            sosTotal: 0,         //sos总量
            isSosPage: true
        }
    },
    methods: {
        getSosMarkerLocation() {     //获取sos定位点
            let that = this;
            that.sosList = [];
            that.markerSos = [];
            let into = (num) => {
                if (that.sosList.length < that.sosTotal || that.sosList.length === 0) {
                    let data = {
                        uId: that.$store.state.userInfo.id,
                        pageNo: num == 0 ? 1 : num,
                        pageSize: 20,
                        status: 0
                    }
                    querySOSRecord(data).then(res => {
                        if (res.data.code == 200) {
                            that.sosTotal = res.data.data.count;
                            let list = res.data.data.list;
                            that.sosList = that.sosList.concat(list);
                            if (that.sosList.length > 0) {
                                into(num + 1);
                            }
                            if(that.sosTotal <= 0){
                                that.$store.state.mapMarkerIndex = 1;
                            }
                        } else {
                            that.$MessageWarning(res.data.msg);
                        }
                    })
                } else {
                    that.sosList = that.sosList.reverse();
                    let sosList = that.sosList;
                    if (sosList.length <= 0) {

                        return
                    }
                    let Uids = sosList.map(item => item.sos_from_id);
                    var params = {
                        Uids: Uids.join(","), //再线成员用户id
                        CorrdinateType: "Wgs84"
                    };

                    axios
                        .post(
                            beasconfig.gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
                            qs.stringify(params),
                            {
                                headers: beasconfig.tokenSl
                            }
                        ).then(res => {
                            let data = res.data.Data;
                            let arr = [];
                            sosList.forEach((item, index) => {
                                if (item.sos_location_at) {
                                    item.sos_location_at = JSON.parse(item.sos_location_at);
                                }
                                // console.log(item.sos_location_at, 111, '666')
                                item.sos_start_time = this.$moment(item.sos_start_time_stamp).format("YYYY-MM-DD HH:mm:ss");
                                item.sos_end_time = this.$moment(item.sos_end_time_stamp).format("YYYY-MM-DD HH:mm:ss");
                                let location = data.find(m => m.Uid == item.sos_from_id);
                                if (location) {
                                    item.Rtimestamp = this.$moment(Number(location.Rtimestamp)).format("YYYY-MM-DD HH:mm:ss");
                                    item.pot = {
                                        Lat: location.Lat,
                                        Lng: location.Lng
                                    }
                                    if (this.$store.state.jumpSosMark.length > 0) {
                                        if (this.$store.state.jumpSosMark[0].id == item.sos_from_id) {
                                            arr.push(item);
                                            this.$store.state.jumpSosMark = [];
                                            this.setSosMarker(item, index, 9999);
                                        }
                                    } else {
                                        arr.push(item);
                                        this.setSosMarker(item, index);
                                    }
                                }
                            });
                            this.setZoom(arr);
                        })
                }
            }
            into(0);
        },
        showMarkerSosLabel(id, type) { //显示lable时间
            var t = document.getElementById(`sosmarker${id}`);//选取label为百度baidu的id进行隐藏
            var labelId = document.getElementById(`sosmarkerlabel${id}`);
            if (labelId) labelId.style.display = type == 1 ? 'none' : 'block';// 以块级样式隐藏
            if (t) {
                if (type == 1) {
                    t.style.display = 'block';// 以块级样式隐藏
                } else {
                    t.style.display = 'none';// 以块级样式隐藏
                }
            }
        },
        overMapSos(id) {
            // console.log(this.markerSos)
            this.markerZindex(id);
            this.showMarkerSosLabel(id, 1);
        },
        outMapSos(id) {
            this.showMarkerSosLabel(id, 0);
        },
        handleSosMark(msgId, index) {        //处理SOS
            let marker = this.markerSos.find(item => item.id == msgId);
            if (this.$store.state.ws) {
                this.$store.state.ws.sosCloseAlarm(this.$store.state.userInfo.User_ID, msgId);
                marker.setMap(null);
                this.markerSos.splice(index, 1);
                EventBus.$emit("cloneSosInfo", msgId);
                this.$message({
                    message: this.$t('message.message.Successfullyprocessed'),
                    type: "warning",
                    center: true,
                    offset: 200
                });
            }
        },

        setSosMarker(item, index, zIndex) {
            item.pot.Lng = global.JudgingHemisphere(item.pot).Lng;
            item.pot.Lat = global.JudgingHemisphere(item.pot).Lat;
            let id = item.sos_msg_id;
            let mapType = this.mapType;
            let htmlTow = `
                <div id="sosmarker${id}" style="display: none;" class='user_marker baidu_white user_marker_left' >
                  <div class="user_marker_box" >
                    <div class='flex_between'>
                        <div class='dtround'>${index + 1}</div>
                        <div class='sosmarkerbut' onclick="handleSosMark(${id},${index})" >
                             ${this.$t('message.dealwith')}
                        </div>
                    </div>
                    <div>${this.$t('message.Name')}：${item.sendName}</div>
                    <div>${this.$t('message.group')}：${item.Cg_Name == null || item.Cg_Name == "" ? this.$t('message.temporarygroup') : item.Cg_Name} </div>
                    <div class='baidu_lAbel_space' >${this.$t('message.Address')}：${item.pot.Lat},${item.pot.Lng}</div>
                    <div class='baidu_lAbel_space' >${this.$t('message.Reportingtime')}：${item.sos_start_time}</div>
                    <div class='baidu_lAbel_space' >${this.$t('message.Positioningtime')}：${item.Rtimestamp}</div>
                  </div>
                  <div class="big" style="bottom: -12px;" ><div class='small grey_white'></div></div>
                </div>
              `;

            let html = `<div id='baidu${id}' onmouseover="overMapSos(${id})" onmouseout="outMapSos(${id})" class='mouse ${mapType == 'Wgs84' ? 'baiduinfo_box2' : 'baiduinfo_box1'}'>
                <div id="sosmarkerlabel${id}" class='baiduinfo1 baidu_white'>
                 <div class='baiduinfo_label1 flex_align'><div class='dtround'>${index + 1}</div> ${item.sendName}</div>
                 <div class="big" style="bottom: -12px;" ><div class='small grey_white'></div></div>
                </div>
            ${htmlTow}
            </div>`

            let googleAnchor = {
                top: 40,
                left: 85
            };
            var position = new google.maps.LatLng(item.pot.Lat, item.pot.Lng);
            let imgUrl = require("@/assets/map/markersos.png");
            const markerLabel = new MarkerWithLabel({
                id: id,
                position: position,
                icon: {
                    url: imgUrl,
                    // 设置图片的大小和偏移量
                    // size: new google.maps.Size(32, 32),
                    // anchor: new google.maps.Point(16, 15)
                },
                map: this.maps, //地图实例
                labelContent: html,//label的内容
                // 调整框框
                labelAnchor: new google.maps.Point(googleAnchor.left, googleAnchor.top),
                labelStyle: {
                    padding: "0px",
                    color: '#ffffff',
                    fontWeight: '400',
                    opacity: 1
                },
                labelClass: ' '
            });
            if (zIndex) {
                markerLabel.setZIndex(9999)
            }
            this.gooleCover.push(markerLabel);
            this.markerSos.push(markerLabel);
        }
    },
    created() {
        EventBus.$on("handleSosMarker", (id) => {
            let index = this.gooleCover.findIndex(item => item.id == id);
            if (index >= 0) {
                this.gooleCover[index].setMap(null);
                this.gooleCover.splice(index, 1);
            }
        });

        EventBus.$on("sosMarkerGps", (id) => {
            let index = this.gooleCover.findIndex(item => item.id == id);
            if (index >= 0) {
                console.log(this.markerSos, this.markerSos.length <= 0, 8888)
                this.maps.setCenter(this.gooleCover[index].position);
                this.markerZindex(id);
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("handleSosMarker");
        EventBus.$off("sosMarkerGps");
    },
    mounted() {
        window.overMapSos = this.overMapSos;
        window.outMapSos = this.outMapSos;
        window.handleSosMark = this.handleSosMark;
    },
    watch: {
        "$store.state.mapMarkerIndex"(i) {
            if (i == 1) {
                this.markerSos = [];
            }
            setTimeout(() => {
                this.isSosPage = i == 1 ? false : true;
            }, 100)
        },
        "$store.state.sosPushInfo"(arr) {
            let index = this.markerSos.length;
            if (this.isSosPage && arr[0] && arr[0].pot) {
                // this.removeMarkerslist();
                this.setSosMarker(arr[0], index, 9999);
                this.maps.setCenter({ lat: arr[0].pot.Lat, lng: arr[0].pot.Lng });
            }
        }
    }
}

export { markersos };